'use strict';

const MMM = require('./journey/index');

I18n.fallbacks = true;

module.exports =  (function($, I18n, undefined) {
  // settings
  var s = {
    locale: 'en',
    context: undefined,
    modules: {
      assessmentToggler: {},
      deathToggler: {},
      externalSubmittion: {},
      sessionTimer: {},
      selectbox:    {},
      updater:      {},
      autosuggest:  {},
      field:        {},
      toggler:      {},
      tooltip:      {},
      modal:        {},
      modalSessionTimeout: {},
      normality:    {},
      converter:    {},
      options:      {},
      targetValidation: {},
      autosize:     {},
      jumpTo:       {},
      zipcodes:     {},
      conditionalMultiChoices: {},
      button:       {},
      confirmation: {},
      yearOfBirth: {},
      reporterType: {}
    },
    initialised: {},
    needToConfirmExit: true,
    supports: {}
  };

  /* --------------------------------------------------------
   * init
   * --------------------------------------------------------
   * called once on page load to initialise
   * the MMM app
  */
  function init() {
    s.context = $('body');
    s.locale = document.documentElement.lang;
    s.supports.animation = supportsAnimation();

    $.subscribe('/updater/add',                 initModules);
    $.subscribe('/updater/add',                 initDropdowns);
    $.subscribe('/updater/add',                 initCustomEvents);
    $.subscribe('/updater/add',                 initInputPatternValidation);
    $.subscribe('/updater/add',                 initMaxlengthOnTextareas);
    $.subscribe('/updater/add',                 initBodyMapIcon);
    $.subscribe('/updater/add',                 focusAnythingSelective);
    $.subscribe('/updater/add',                 initAutoExpandTextAreas);
    $.subscribe('/updater/add/ajax_template',   addJumpToLink);
    $.subscribe('/updater/add/ajax_template',   toggleAdditionalMedicine);
    $.subscribe('/updater/remove_template',     removeSideEffectLink);
    $.subscribe('/updater/age',                 updatePregnancyToggle);
    $.subscribe('/updater/update',              MMM.normality.update);
    $.subscribe('/selectbox/focus',             MMM.field.focus);
    $.subscribe('/selectbox/blur',              MMM.field.blur);
    $.subscribe('/calendar/open',               calendarUpdate);
    $.subscribe('/calendar/update',             calendarUpdate);
    $.subscribe('/calendar/select',             calendarSelect);
    $.subscribe('/updater/error/add',           addError);
    $.subscribe('/updater/error/remove',        removeError);
    $.subscribe('/modal/complete',              initModal);
    $.subscribe('/autosuggest/form/changed',    validateForm);
    $.subscribe('/updater/update/jump_to_link', updateJumpToLink);
    $.subscribe('jump-to/jumped',               focusAnythingSelective);

    $.subscribe('/accordion/add', function(accordion, o) {
      MMM.toggler.setup(accordion, o.contentSelector, o.titleSelector);
    });

    copyReporterAddress();
    listenToChangeProductCountry();
    listenToSaveProductCountry();
    $.subscribe('/modal/close', closeChangeProductCountry);

    $.subscribe('/toggler/open', function(toggler, content) {
      var updaters = content.find(MMM.updater.selector);
      if (updaters.length) {
        MMM.updater.updateAddTriggers(updaters);
      }

      if (content.is('#outcome-death')) {
        toggleDeathModule(toggler, content);
      }
    });

    $.subscribe('/toggler/close', function(toggler, content) {
      // stop liver lab group content from being disabled
      if (toggler.parents('.m-labs-links').length) {
        content.find(':input').prop('disabled', false);
      }
    });

    $.subscribe('/autosuggest/select', function(e, ui) {
      var input = $(e.target),
          loadingClass = 'ui-autocomplete-loading';
      // when an item is selected, if we can't
      // retrieve its template, we need to empty
      // the field and remove it from the autosuggests
      // selectedItems cache
      input.addClass(loadingClass);
      MMM.updater.submit(e, ui).fail(function() {
        input.removeClass(loadingClass).val('');

        if (ui) {
          MMM.autosuggest.removeSelectedItem(ui.item.label);
        }
      });
    });

    $.subscribe('/autosuggest/new/close', function(e, ui) {
      MMM.modal.close();
    });

    $.subscribe('/autosuggest/create', function(modal, termName, codedValues, productCountry) {
      MMM.modal.open({
        inline: true,
        href: modal,
        overlayClose :false,
        escKey : false,
        onComplete: function() {
          updateModalTextarea(modal, termName);
          updateModalCountry(modal, productCountry);
          updateModalAutosuggest(modal, codedValues);
          removeModalCloseButton();
      }});
    });

    $.subscribe('/updater/submit', function(el) {
      // toggle the hidden toggle within the updater
      toggleUpdater('submit', el);
    });

    $.subscribe('/updater/remove', function(el) {
      // toggle the hidden toggle within the updater
      var valueEl = el.find('.m-field-readonly:eq(0)'),
          hiddenEl = valueEl.find('input[type="hidden"]:eq(0)'),
          customName = el.find('[data-path="adverse_events.event.custom_name"]'),
          textValue = $.trim(valueEl.text());
      toggleUpdater('remove', el);
      if (hiddenEl.length) MMM.autosuggest.removeSelectedItem(hiddenEl.val());
      if (customName.length) MMM.autosuggest.removeSelectedItem(customName.val());
      MMM.autosuggest.removeSelectedItem(textValue);
    });

    $.subscribe('/updater/expired', function() {
      // reload so that the page redirects to
      // beginning of report with session
      // expired message
      s.needToConfirmExit = false;
      window.top.location.href = '/users/auto_sign_out';
    });

    // Call popup for product or adverse event(meddra) edit
    $.subscribe('/updater/edit', function(el, oldValues) {
      var autosuggest = el.find('input[data-autosuggest-create="true"]').first(),
          codedValues = {},
          allValues = {},
          TERM_NAME = 0;

      $.each(oldValues, function (i, val) {
        var reg = /\[custom_([a-z_]+)\]$/i,
            matches = reg.exec(val.name),
            name;

        if (matches) {
          name = matches[1];
          codedValues[name] = val.value;
        }

        var reg = /\[country\]\[([a-z_]+)\]$/i,
            matches = reg.exec(val.name),
            name;

        if (matches) {
          name = matches[1];
          allValues['country'] = val.value;
          TERM_NAME = 1;
        }

        var reg = /\[([a-z_]+)\]$/i,
            matches = reg.exec(val.name),
            name;

        if (matches) {
          name = matches[1];
          allValues[name] = val.value;
        }

      });
      autosuggest.data('field', autosuggest.data('field-edit'))
      // if someone is trying to edit an autosuggest
      // that has the "create" option we need to
      // open a lightbox to edit it instead
      if (autosuggest.length) {
        MMM.autosuggest.showCustomInput(
          autosuggest,
          oldValues[TERM_NAME].value,
          codedValues,
          allValues
        );
      }
    });

    var ageSelectors = [
      MMM.field.selector + '-age > :input',
      MMM.field.selector + '-yob > :input'
    ];

    s.context.on('change', ageSelectors.join(','), function() {
      publishAge();
    });

    // timeout all AJAX requests
    // after 10 seconds
    $.ajaxSetup({ timeout: 10000 });

    initModules(s.context);
    initDropdowns('body');
    initCustomEvents(s.context);
    initRadioAndCheckboxClickListeners(s.context);
    initInputPatternValidation(s.context);
    initSkipLink();
    initBreadcrumb();
    initConfirmExit();
    initMaxlengthOnTextareas(s.context);
    initBodyMapIcon(s.context);
    initAutoExpandTextAreas();
    initElmHelpers();

    // The fields needed to publish the age are hidden until toggler runs, so we wait for it
    setTimeout(function() {
      publishAge();
    }, 0)

    if ($('body.page-what_happened').length){
      MMM.updater.toggleNoAdverseEventButton();
    }

    s.context.on('blur', '.date-symptom-start', updateSymptomWorsenDate);
    s.context.on('blur', 'input.email-address', validateEmail);
    s.context.on('keydown', disableInputEnter);
    s.context.on('keydown', disablePageBackspace);
    s.context.on('click', '[type="submit"]', handleSubmit);

    // on press ENTER submit the input
    s.context.on('keyup', '.m-autosuggest-disabled', function (e, force) {
      var target = $(e.target);

      if (target.val().trim().length == 0) {
        target.val(target.val().trim());
      }
      if ((e.keyCode === 13 || force) && (target.val().length)) {
        target.val(target.val().trim()); // Trim out the space at the start of term
        MMM.updater.submit(e);
      }
    });

    s.context.on('click', '.m-autocomplete-add-button', function(){
      // we need the input event to be passed in the submit function
      $('.m-autosuggest-disabled').trigger('keyup', true)
    });

    s.context.on('submit', 'form', function (e) {
      s.needToConfirmExit = false;
    });

    initTextareas();
    initAutoExpandTextAreas();
    initCountrySelector();
    initLanguageSelector();
    initDisabled();

    $('.m-options').each(function (_i, options) {
      if($(options).has('.field_with_errors').length) {
        if($(options).has('.m-group__content').length) {
          $(options).find('.m-group__content').addClass('field_with_errors')
        } else {
          $(options).addClass('field_with_errors no-position-correction')
        }
      }
    });
  }

  function initDisabled() {
    // pointer event none, in CSS, removes all events, we just want to remove click event
    $(document).on('click', '.disabled, [disabled], .disabled *, .ui-state-disabled', function () {
      return false;
    });
  }

  function initLanguageSelector() {
    $('[id^="language_selector_language"]').on('change', function(event) {
      if (I18n.locale !== event.target.value) {
        window.location.pathname = $(event.target).data('url');
      }
    });
  }

  function initCountrySelector() {
    $('#language_selector_country').on('change', function(event) {
      window.location.search = addUrlParam(window.location.search, 'country', event.target.value);
    });
  }

  function addUrlParam(search, key, val) {
    var newParam = key + '=' + val;

    if (search) {
      var params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);
      return (params === search) ? params += '&' + newParam : params;
    } else {
      return newParam;
    }
  }

  function initTextareas() {
    $('textarea')
      .mousedown(function () {
        $(this).data('clicking', true);
      })
      .click(function () {
        $(this).data('clicking', false);
      })
      .focus(function () {
        var $this = $(this);
        if (!$this.data('clicking')) {
          setTimeout($.proxy(setCaretAtEnd, null, $this), 0);
        }
      });
  }

  function initAutoExpandTextAreas() {
    $('textarea.auto-expand').each(function (i, textArea) {
      expandTextArea(textArea);
    });

    $(document).on('input', 'textarea.auto-expand', function () {
      expandTextArea(this);
    });
  }

  function expandTextArea(textArea) {
    var $this = $(textArea);
    $this.css({height: 'auto'});
    // NOTE: IE11 returns "" for $this.css('padding') which broke autoexpand.
    //       0 (zero) is here as a fallback value, in case padding was not found for any reason.
    var newHeight = textArea.scrollHeight - (parseInt($this.css('padding'), 10) || 0) * 2;
    if ($this.css('boxSizing') === 'border-box') {
      newHeight = textArea.scrollHeight
    }

    // An ugly hack to ensure we always show a couple of lines of text in the
    // event that the control was hidden. Its 0 since a hidden (as in display:
    // hidden) block has a height of 0 - we need to have outer wrappers and
    // use visibility to fix (or do this 100% using css).
    if (newHeight <= 0) {
      newHeight = 71
    }

    $(textArea).css('height', newHeight);
  }

  function handleSubmit(e) {
    $.publish('/updater/validate', [s.context]);

    var errors = s.errorListMessages;

    // don't want to submit the form
    // if there are any JS errors
    // remaining
    if (errors && errors.length) {
      scrollToError();
      e.preventDefault();
      return false;
    } else {
      // never need to confirm exit if
      // submitting the form without errors
      s.needToConfirmExit = false;
      return true;
    }
  }

  /* --------------------------------------------------------
   * initModules
   * --------------------------------------------------------
   * calls init method for all unitialised modules if
   * context contains the module selector
  */
  function initModules(context, force) {
    $.each(s.modules, function(module, opts) {
      if ((!s.initialised[module] || force) && MMM[module]) {
        var selector = MMM[module].selector;

        // do nothing if there is a selector but
        // we can't find any matches on the page
        if (!MMM[module].alwaysInit && selector && !$(context).findMe(selector).length) {
          return;
        }

        /**
         * Each module can now be configured in the HTML using the prefix opts
         * The options will be merge with the s.options of each modules
         * @example
         * data-opts-fixed-header combined with jump-to module will set the module fixedHeader's option
         * to data-opts-fixed-header's value
         */
        opts = $.extend({}, opts, {locale: s.locale});
        if ($(context).findMe(selector).data()) {
          var dataOpts = {};
          $(Object.keys($(context).findMe(selector).data())).each(function (idx, data) {
            if (data.indexOf('opts') == 0) {
              dataOpts[changeCaseAt(trimString(data, 'opts'))] = $(context).findMe(selector).data(data);
            }
          });
          opts = $.extend({}, opts, dataOpts);
        }

        MMM[module].init(opts);
        s.initialised[module] = true;
        $.publish('/updater/add/' + module, [context]);
      }
    });
  }

  /*
  @description Copy reporter address to addtional HCP address fields
  */
  function copyReporterAddress() {
    $('#copy_button').click(function() {
      var field_name = $('#patient-details').hasClass('slide') ? 'patient_details' : 'reporter_details',
          reporter_address_line_1 = $('#report_data_' + field_name + '_address_line_1').val(),
          reporter_address_line_2 = $('#report_data_' + field_name + '_address_line_2').val(),
          reporter_town = $('#report_data_' + field_name + '_town').val(),
          reporter_county = $('#report_data_' + field_name + '_county').val(),
          reporter_postcode = $('#report_data_' + field_name + '_postcode').val(),
          reporter_country = $('#report_data_' + field_name + '_country').find(':selected').val()

      $('#report_data_patient_seen_gp_address_line_1').val(reporter_address_line_1);
      $('#report_data_patient_seen_gp_address_line_2').val(reporter_address_line_2);
      $('#report_data_patient_seen_gp_town').val(reporter_town);
      $('#report_data_patient_seen_gp_county').val(reporter_county);
      $('#report_data_patient_seen_gp_postcode').val(reporter_postcode);
      $('#report_data_patient_seen_gp_country_value').val(reporter_country);
      refreshCountryDropdown($('#report_data_patient_seen_gp_country_value'));
    });
  }

  /*
  @description Refresh selectmenu after assigned selected value
  @param Element of country selection dropdown
  */
  function refreshCountryDropdown(countrySelector) {
    countrySelector.selectmenu("destroy").selectmenu({ style: "dropdown" });
    var select = countrySelector.selectmenu(s.options),
        data = select.data('selectmenu'),
        button = data.newelement;
    button.find('.ui-icon').append('<span />');
    $('.m-zipcodes-country-selector').trigger('change');
  }

  /* --------------------------------------------------------
   * initDropdowns
   * --------------------------------------------------------
   * initialise dropdown functionality within context
  */
  function initDropdowns(context) {
    var selector = '.m-dropdown',
        trigger = '.m-dropdown-trigger',
        dropdown = $(context).find(selector),
        content = 'ul';

    // only init if there are
    // dropdowns within the context
    if (!dropdown.length) {
      return;
    }

    // turn dropdown into a toggle
    MMM.toggler.setup(dropdown, content);

    // stop trigger link from going
    // anywhere when clicked
    dropdown.find(trigger).on('click', function(e) {
      e.preventDefault();
    });

    // toggle on mouseenter/mouseleave
    dropdown.on('mouseenter mouseleave', function(e) {
      var toggle = $(this),
          element = toggle.find(content),

          go = function() {
            toggle.trigger('click');
          };

      clearTimeout(s.dropDownTimer);

      if (e.type === 'mouseenter' && element.is(':hidden')) {
        // only drop down after a certain number
        // of milliseconds to prevent dropdown from
        // appearing when user swipes mouse across screen
        s.dropDownTimer = setTimeout(go, 200);
      }

      if (e.type === 'mouseleave' && element.is(':visible')) {
        go();
      }
    });
  }

  /* --------------------------------------------------------
   * initCustomEvents
   * --------------------------------------------------------
  */

  function initRadioAndCheckboxClickListeners(context) {
    context = $(context);
    // set up custom check/uncheck
    // events on radio buttons
    if(!context.data('selective')){
      initRadioCheckUncheckEvents(context);
    }

    // set up custom check/uncheck
    // events on checkboxes
    initCheckboxCheckUncheckEvents(context);
  }

  function initCustomEvents(context) {
    // make sure that whenever we trigger
    // check/uncheck that the ui reflects it
    updateCheckedAttrOnCheckUncheck($(context));
  }

  /* --------------------------------------------------------
   * updateCheckedAttrOnCheckUncheck
   * --------------------------------------------------------
  */
  function updateCheckedAttrOnCheckUncheck(context) {
    context.on('check uncheck', ':radio, :checkbox', function(e) {
      var checked = (e.type === 'check');

      $(this)
        .prop('checked', checked)
        .data('ischecked', checked)
        .toggleClass('checked', checked);

      var button = $(this).parents('label')
        .find('.m-button:not(.ng-driven)');
      (checked) ? button.addClass('m-options-checked') : button.removeClass('m-options-checked');

      e.stopPropagation();
    });
  }

  function removeModalCloseButton() {
    var button = MMM.modal.getCloseButton();
    if (button) button.remove();
  }

  function updateModalTextarea(modal, value) {
    var textarea = modal.find('textarea');
    textarea.val(value);
    setCaretAtEnd(textarea);
    textarea.focus();
  }

  function updateModalAutosuggest(modal, codedValues) {
    var customSuggest, event;
    if (codedValues && codedValues.name) {
      customSuggest = modal.find('.m-autosuggest');
      customSuggest.val(codedValues.name);
      event = $.Event('select');
      event.target = customSuggest;
      codedValues.label = codedValues.value = codedValues.name;
      MMM.autosuggest.select(event, { item: codedValues });
    }
    focusAnythingSelective(modal)
  }

  function updateModalCountry(modal, productCountry) {
    var countrySelector = modal.find('.product_country_dropdown select'),
        countryTextSelector = modal.find('.terms_country'),
        selectedCountryName;
    if (countrySelector.length) {
      countrySelector.val(productCountry);
      refreshCountryDropdown(countrySelector);
      selectedCountryName = countrySelector.find('option:selected').text();
      countryTextSelector.text(selectedCountryName);
    }
  }

  /* --------------------------------------------------------
   * initRadioCheckUncheckEvents
   * --------------------------------------------------------
  */
  function initRadioCheckUncheckEvents(context) {
    context.on('click', ':radio', function(e) {
      var radio = $(this);

      if (radio.data('ischecked')) {
        // allow radios to be unselected if they
        // don't have the disable-unselect class
        if (!radio.hasClass('disable-unselect')) {
          radio.trigger('uncheck');
        }

        return;
      }

      radio.trigger('check');
      e.stopPropagation();
    });

    // when the radio selection is changed, fire
    // the uncheck event on the selected radio's
    // sibling that was checked
    context.on('change', ':radio', function(e) {
      var siblings = context.find(':radio[name="' + this.name + '"]').not(this);

      siblings.each(function() {
        var sibling = $(this);
        if (sibling.data('ischecked')) {
          sibling.trigger('uncheck');
        }
      });

      e.stopPropagation();
    });
  }

  /* --------------------------------------------------------
   * initCheckboxCheckUncheckEvents
   * --------------------------------------------------------
  */
  function initCheckboxCheckUncheckEvents(context) {
    context.on('click', ':checkbox', function(e) {
      var input = $(this);

      input.trigger((input.is(':checked') ? 'check' : 'uncheck'));
      e.stopPropagation();
    });
  }

  /* --------------------------------------------------------
   * initInputPatternValidation
   * --------------------------------------------------------
   * inputs with pattern attributes will empty on blur
   * if their value does not match the pattern
  */
  function initInputPatternValidation(context) {
    $(context).on('blur', ':input[pattern]', function(e) {
      var input = $(this),
          pattern = new RegExp(input.attr('pattern'));

      if (!pattern.test(this.value)) {
        this.value = '';
        input.trigger('change');
        input.trigger('keyup');
      }
    });
  }

  /* --------------------------------------------------------
   * initSkipLink
   * --------------------------------------------------------
  */
  function initSkipLink() {
    // set tabindex on the main div so
    // IE knows it's focusable, and so
    // Webkit browsers will focus() it
    s.context.attr('tabindex', -1);

    // if there's a '#' in the URL,
    // focus element with that ID
    if (document.location.hash) {
      $(document.location.hash).focus();
    }

    // set focus to in page-link targets when clicked
    $('a[href^="#"]').click(function(e){
      var selector = this.href.split('#')[1];
      if(selector){
        $('#' + selector).focus();
      }
    });
  }

  /* --------------------------------------------------------
   * initBreadcrumb
   * --------------------------------------------------------
  */
  function initBreadcrumb() {
    var selector = '.m-breadcrumb a, .error-link a, a.m-button-change, a.m-button-prev, a.m-button-next',
      form = s.context.find('#report-form-wrapper > form');

    $(selector).on('click', function(e) {
      if (handleSubmit(e)) {
        form.attr('action', this.href).
          submit();
        e.preventDefault();
      }
    });
  }

  /* --------------------------------------------------------
   * initConfirmExit
   * --------------------------------------------------------
  */
  function initConfirmExit(context) {
    var noConfirmPages = [
          'confirmation',
          'unsupported',
          'admin',
          'terms',
          'privacy-policy'
        ],

        confirm = function() {
          // never need to confirm exit
          // for the noConfirmPages
          $.each(noConfirmPages, function(i, page) {
            if (document.getElementById(page)) {
              s.needToConfirmExit = false;
            }
          });

          if (s.needToConfirmExit) {
            return I18n.t('javascript.leave_page');
          }
        }

    context = context || s.context;

    // if the are clicking an internal link
    // we don't need to confirm exit
    context.on('click', 'a[href^="/"]:not(.m-modal), *[data-no-confirm]', function() {
      s.needToConfirmExit = false;

      // if it opened in a new window
      // then need to set flag back
      // after window opens
      if ($(this).attr('target') === '_blank') {
        setTimeout(function() {
          s.needToConfirmExit = true;
        }, 0);
      }
    });

    if ($('body.m-no-confirm').length === 0) {
      window.onbeforeunload = confirm;
    };

    if ($('.onbeforenull').length > 0) {
      window.onbeforeunload = null;
    }
  }

  /* --------------------------------------------------------
   * initModal
   * --------------------------------------------------------
   * initialises inidividual modal module for necessary
   * modal type
  */
  function initModal(modal) {
    var hasSubModal = false,
        subModal, subModalInst, prop, closeButton;

    for (prop in MMM.modal) {
      subModal = MMM.modal[prop];

      if (subModal.selector) {
        subModalInst = modal.find(subModal.selector);
        closeButton = MMM.modal.getCloseButton();

        if (subModalInst.length) {
          hasSubModal = true;

          subModal.init(subModalInst, {
            locale: s.locale,
            closeButton: closeButton
          });

          // update custom UI
          $.publish('/updater/add', [subModalInst]);

          // resize modal as new UI may
          // cause content to be taller
          $.publish('/modal/update');
        }
      }
    }

    if (!hasSubModal) {
      // update custom UI
      $.publish('/updater/add', [modal, true]);
      initConfirmExit(modal);
    }
  }

  /* --------------------------------------------------------
   * initMaxlengthOnTextareas
   * --------------------------------------------------------
  */
  function initMaxlengthOnTextareas(context) {
    context.find('textarea[maxlength]').on('input propertychange', function(e) {
      var textarea = $(this),
          maxlength = textarea.attr('maxlength');

      if (maxlength && textarea.val().length > maxlength) {
        textarea.val(textarea.val().substring(0, maxlength));
      }
    });
  }

  /* --------------------------------------------------------
   * toggleDeathModule
   * --------------------------------------------------------
   * death module is only hidden when all toggles are
   * untoggled
  */
  function toggleDeathModule(toggler, content) {
    s.deathToggles = s.deathToggles || [];
    setTimeout(function() {
      var checked = toggler.is(':checked');

      // fool toggler into thinking
      // it's closed when it's actually
      // open ready for the next toggler
      content.data('toggle-up', true);
      s.deathToggles[checked ? 'push' : 'pop'](1);

      if (!checked && !s.deathToggles.length) {
        // when unchecking the last
        // toggle make sure toggler
        // thinks it is open...
        content.data('toggle-up', false);
        // ...and then toggle it to
        // force it to close
        MMM.toggler.toggle(toggler, toggler.data('toggler'), [content]);
      }
    }, 0);
  }

  /* --------------------------------------------------------
   * updatePregnancyToggle
   * --------------------------------------------------------
   * pregnancy fields rely on patients age being within a
   * particular range so disable toggle if it's not
  */
  function updatePregnancyToggle(field, age) {
    var agePicker = s.context.find('.pregnancy-range').first(),
        minAge = agePicker.data('min-pregnancy-age'),
        maxAge = agePicker.data('max-pregnancy-age');

    publishPregnancyToggleEvent(age, minAge, maxAge, field);
  }

  function publishPregnancyToggleEvent(age, minAge, maxAge, field) {
    var pregnancyToggle = field.nearest('.pregnancy-toggle'),
        isChecked = pregnancyToggle.is(':checked');
    if (age !== null && (age < minAge || age > maxAge)) {
      $.publish('/updater/destroy/toggler', [pregnancyToggle]);
    } else if (!pregnancyToggle.data('toggler') && !isNaN(age)) {
      $.publish('/updater/add/toggler', [pregnancyToggle]);

      // if it was checked then we need to trigger
      // the check event again after binding the toggler
      if (isChecked) {
        pregnancyToggle.trigger('check');
      }
    }
  }

  /* --------------------------------------------------------
   * publishAge
   * --------------------------------------------------------
  */
  function publishAge() {
    var selector = MMM.field.selector,
        ageField, dobField, yobField;

    ageField = s.context.find(selector + '-age:visible');
    dobField = s.context.find(selector + '-dob:visible');
    yobField = s.context.find(selector + '-yob:visible');

    if (ageField.length) {
      publishAgeFromAge(ageField);
    }
    if (dobField.length) {
      publishAgeFromDob(dobField);
    }
    if (yobField.length) {
      publishAgeFromDob(yobField);
    }
  }

  /* --------------------------------------------------------
   * publishAgeFromAge
   * --------------------------------------------------------
  */
  function publishAgeFromAge(field) {
    var div = { d: 365, m: 12, y: 1 },
        age = field.find('input').val(),
        type = field.find('select').val(),
        years = age.toString().length > 0 ? age / div[type] : null;
    $.publish('/updater/age', [field, years]);
  }

  /* --------------------------------------------------------
   * publishAgeFromDob
   * --------------------------------------------------------
  */
  function publishAgeFromDob(field) {
    var today = new Date(),
        birth = new Date(field.find('input').val()),

        age = today.getFullYear() - birth.getFullYear(),
        m = today.getMonth() - birth.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }

    $.publish('/updater/age', [field, age]);
  }

  /* --------------------------------------------------------
   * validateEmail
   * --------------------------------------------------------
  */
  function validateEmail(e) {
    var regex = MMM.globals.emailRegex,
        valid = (regex.test(this.value)*1),
        $this = $(this),
        updateError = [addError, removeError],
        errorArgs = [I18n.t('javascript.email_error'), $this];

    if (this.value || $this.prop('required')) {
      updateError[valid].apply(null, errorArgs);
    } else {
      removeError.apply(null, errorArgs);
    }
  }

  /* --------------------------------------------------------
   * calendarUpdate
   * --------------------------------------------------------
   * called whenever calendar is opened/updated
  */
  function calendarUpdate(input, inst) {
    MMM.field.create(inst.dpDiv);
    $.publish('/updater/add', [inst.dpDiv]);
  }

  /* --------------------------------------------------------
   * toggleUpdater
   * --------------------------------------------------------
   * clicks hidden toggle within updater if one exists
  */
  function toggleUpdater(type, el) {
    var updater = $(el),
        toggle = updater.find(MMM.toggler.selector + '.hide');

    if (toggle.length) {
      toggle.trigger('click');
    }
  }

  /* --------------------------------------------------------
   * updateSymptomWorsenDate
   * --------------------------------------------------------
   * worsen date shouldn't be less than start date so we
   * need to set the minDate to the start date on worsen
   * range from date field
  */
  function updateSymptomWorsenDate() {
    var startDate = $(this),
        worsen = startDate.nearest('.date-symptom-worsen'),
        data = {from: {}};

    if (worsen.length) {
      data.from.minDate = new Date(startDate.val());
      MMM.range.update(worsen, data);
    }
  }

  /* --------------------------------------------------------
   * calendarSelect
   * --------------------------------------------------------
   * called when a date is selected from a calendar
  */
  function calendarSelect(date, inst) {
    if (inst.input.hasClass('date-symptom-start')) {
      updateSymptomWorsenDate.call(inst.input);
    }
    if (inst.input.hasClass('date-of-birth')) {
      publishAge();
    }
  }

  /* --------------------------------------------------------
   * disableInputEnter
   * --------------------------------------------------------
   * stops browser from submitting form when user hits enter
   * key within an input.
  */
  function disableInputEnter(e) {
    var keyCode	= $.ui.keyCode,
        target;

    if (e.keyCode === keyCode.ENTER) {
      target = $(e.target);
      // Check that the target is a form field, not a textarea
      // and not within a "login form".
      if (target.is('input') && !target.is('[type=submit]') && !target.is('.login-form *')) {
        e.preventDefault();
      }
    }
  }

  /* --------------------------------------------------------
   * disablePageBackspace
   * --------------------------------------------------------
   * stops browser from going back
   * a page when user hits backspace key outside of an input
   * --------------------------------------------------------
   */
  function disablePageBackspace(e) {
    var keyCode 	  = $.ui.keyCode,
        isBackspace = (e.keyCode === keyCode.BACKSPACE);

    if (isBackspace) {
      // We're putting this within another condition so that
      // the target isn't checked on every key stroke.
      if (!$(e.target).is(':input')) {
        e.preventDefault();
      }
    }
  }

  /* --------------------------------------------------------
   * addError
   * --------------------------------------------------------
  */
  function addError(msg, el, context) {
    s.errorListMessages = s.errorListMessages || [];

    // always error field even when error is in
    // list as it might be a different field with
    // the same error message
    if (el && !el.parent('.field_with_errors').length && !el.hasClass('no-wrap-in-error')) {
      addErrorStyling(el);
    }

    if (msg && $.inArray(msg, s.errorListMessages) > -1) {
      scrollToError();
      return;
    }

    s.$errorList = $('.m-errors', context);

    if (!s.$errorList.length) {
      s.$errorList = $('<ul class="m-errors" />');
      s.$errorList[context ? 'prependTo' : 'insertBefore'](context || s.context.find('.main-wrapper'));
    }

    s.$errorList.append('<li>' + msg + '</li>');
    s.errorListMessages.push(msg);
    scrollToError();
  }

  /* --------------------------------------------------------
   * removeError
   * --------------------------------------------------------
  */
  function removeError(msg, el, emptyErr) {
    // Remove all errors if emptyErr is true
    if (emptyErr == true && s.$errorList) {
      s.$errorList.remove();
      s.errorListMessages = [];
      delete s.$errorList;
    } else {
      var arrPos = $.inArray(msg, s.errorListMessages);

      if (typeof msg !== 'string' && msg) {
        removeErrorStyling(msg);
        return;
      }

      // if there is no error list or the
      // error we're trying to remove doesn't
      // exist then do nothing
      if (!s.$errorList || !s.$errorList.length || arrPos < 0) {
        return;
      }

      s.$errorList.find('li').each(function() {
        var error = $(this);

        if (error.text() === msg) {
          error.remove();
        }

        // remove the error list from the
        // DOM if there are no errors inside
        // it anymore
        if (!error.siblings().length) {
          s.$errorList.remove();
        }
      });

      removeErrorStyling(el);

      s.errorListMessages.splice(arrPos, 1);
    }
  }

  function addErrorStyling(el) {
    // Input fields lose focus during wrpa/unwrap operations
    preserveFocus(() => el.wrapAll('<span class="field_with_errors" />'));
  }

  function removeErrorStyling(el) {
    preserveFocus(() => {
      if (el && el.parent('.field_with_errors').length) {
        el.unwrap();
      }
    });
  }

  function preserveFocus(operation) {
    let focusedElement = $(document.activeElement);
    // NOTE: This is for IE11 and before. Without `blur` event, IE11 will not restore focus for the
    //       element, that was detached during wrap/unwrap process.
    //       This may cause a double `blur` event, but is a lesser evil.
    focusedElement.blur();
    operation();
    if (!focusedElement.is($(document.activeElement))) {
      setTimeout(function(){
        focusedElement.focus();
      }, 0);
    }
  }

  /* --------------------------------------------------------
   * scrollToError
   * --------------------------------------------------------
  */
  function scrollToError() {
    // scroll the page to
    // the error list
    if (!s.$errorList.is(':in-viewport')) {
      $('body').stop(true, true).animate({
        scrollTop: s.$errorList.offset().top
      }, 500);
    }
  }

  /* --------------------------------------------------------
   * addSideEffectLink
   * --------------------------------------------------------
   */
  function addJumpToLink(el, appendToEl) {
    /**
     * for this to work the links should always be wrapped in the .click-tru-links and have a
     * .click-tru-links__list
     * @example
     * <div class="click-tru-links">
     *   <span class="click-tru-links__list">
     *    <ul></ul>
     *   </span>
     * </div>
     */
    var name = $.trim(el.data('name')),
      id = el.attr('id');

    if (!name || !id) {
      return false;
    }

    // @todo: if this should be reused, abstract class
    var $sideEffectLinks = $('.click-tru-links');

    var $appendTo = appendToEl ? $sideEffectLinks.find(appendToEl) : $sideEffectLinks.find('ul').first();
    if ($sideEffectLinks.length) {
      var $li = $('<li>');
      $('<a>').addClass('m-jump-to').attr({
        'data-id': id,
        'data-opts-animate': true,
        'data-opts-fixed-header': '.header-sticky'
      }).text(name).appendTo($li);

      $appendTo.append($li);
      display($sideEffectLinks);
      display($appendTo);

      // make sure the menu appear before to make calculation
      $.when($sideEffectLinks.is(':visible')).then(function () {
        var headerSticky = $('.header-sticky');
        // the offset top change between when the header is `stuck` and when it's not
        // Scroll one time and check check if it requires further scroll
        $('html, body').animate(
          { scrollTop: $('#' + id).offset().top - $('.header-sticky').outerHeight() },
          1,
          function () {
            var newHeaderHeight = headerSticky.outerHeight();
            if (window.scrollY !== $('#' + id).offset().top - newHeaderHeight) {
              $('html, body').animate({ scrollTop: $('#' + id).offset().top - newHeaderHeight });
            }
          });
      });
    }

    function display(el) {
      if (el.hasClass('hide')) {
        el.removeClass('hide');
      }
    }
  }

  /**
   * @description: takes an element and if this element has data `selective` focus it
   * @param el - JS element or jQuery element
   */
  function focusAnythingSelective(el) {
    // make sure focus is only set on the templates in modal
    if ($(el).data('selective')) {
      $(el)
        .attr('tabindex', -1) // makes anything focusable
        .css('outline', 'none') // no style when focused
        .focus();
    }
  }

  /* --------------------------------------------------------
   * removeSideEffectLink
   * --------------------------------------------------------
   */

  function removeSideEffectLink(el) {
    // @see comments in addSideEffectLink
    $('.click-tru-links__list [data-id=' + el.attr('id') + ']').closest('li').remove();
    if (!$('.click-tru-links li').length) {
      $('.click-tru-links').addClass('hide');
    }
  }

  function toggleAdditionalMedicine(element) {
    if ((element[0].id === 'primary' || element[0].className == 'page-products') && $('.page-products').length) {
      $('#primary_picker').addClass('hide').find('.m-toggle').trigger('check.toggler');
    }
  }

  /* --------------------------------------------------------
   * initBodyMapIcon
   * --------------------------------------------------------
  */
  function initBodyMapIcon(context) {
    $(context).find('.m-bodymap').each(function() {
      if (!s.supports.animation) {
        initIEBodyMapIcon.call(this, context);
      }

      animateBodyMapIconOnHover.apply(this);
    });
  }

  function initIEBodyMapIcon(context) {
    var $this = $(this),
        animation = glowBodyMapIcon.apply(this),
        toggleTarget = $this.parents('#ae');

    if (toggleTarget.is('.hide')) {
      animation.stop();
    }

    function opened(toggler, element) {
      if (element.is(toggleTarget)) {
        animation.start();
      }
    }

    function closed(toggler, element) {
      if (element.is(toggleTarget)) {
        animation.stop();
      }
    }

    $.subscribe('/toggler/open', opened);
    $.subscribe('/toggler/close', closed);
  }

  /* --------------------------------------------------------
   * animateBodyMapIconOnHover
   * --------------------------------------------------------
  */
  function animateBodyMapIconOnHover() {
    var iconParent = $(this),
        icon = iconParent.find('.m-bodymap-icon'),
        firstFrame = true,
        spriteWidth = 2060,
        iconWidth = icon.width(),
        gutter = 30,
        timer;

    function animate() {
      timer = setTimeout(function() {
        var pos, left;

        if (firstFrame) {
          icon.css('background-position', '0 0');
          firstFrame = false;
        }

        pos = icon.css('background-position').split(' ');
        left = (parseInt(pos[0], 10) - iconWidth - gutter);
        icon.css('background-position', left + 'px ' + pos[1]);

        if (left !== -(spriteWidth - iconWidth)) {
          animate();
        }
      }, 50);
    };

    iconParent.on('mouseover', animate);

    iconParent.on('mouseout', function() {
      icon.attr('style', '');
      clearTimeout(timer);
      firstFrame = true;
    });
  }

  /* --------------------------------------------------------
   * glowBodyMapIcon
   * --------------------------------------------------------
   * used for IE as it does not support CSS animation
  */
  function glowBodyMapIcon() {
    var $parent = $(this),
        $el = $parent.find('.m-field'),
        color = $el.css('color'),
        rgb = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/),
        strength = 1,
        maxStrength = 7,
        fwd = true,
        timer;

    // convert color RBG to hex
    if (rgb) {
      rgb.shift();

      $.each(rgb, function(i) {
        rgb[i] = parseInt(rgb[i]).toString(16);
        if (rgb[i].length == 1) {
          rgb[i] = '0' + rgb[i];
        }
      });

      color = '#' + rgb.join('').toUpperCase();
    }

    function animate() {
      var glow = 'progid:DXImageTransform.Microsoft.Glow(Color={{color}},Strength={{strength}})';

      timer = setTimeout(function() {
        if (!$el.closest('body').length) {
          // element has been removed from
          // the DOM so do nothing
          return;
        }

        glow = glow.replace('{{color}}', color);
        glow = glow.replace('{{strength}}', strength);
        $el.css('filter', glow);

        $.each(['left', 'top'], function(i, side) {
          $el.css(side, ($el.position()[side] + (fwd ? -1 : 1)));
        });

        if (strength == maxStrength) { fwd = false; }
        if (!fwd && strength === 1) { fwd = true; }

        strength += fwd ? 1 : -1;
        animate();
      }, 200);
    }

    function stop() {
      clearTimeout(timer);
      $el.css('filter', 'none');
    }

    $parent.on('mouseover', function() {
      clearTimeout(timer);
    });

    $parent.on('mouseout', animate);

    animate();

    return {
      start: animate,
      stop: stop
    };
  }

  /* --------------------------------------------------------
   * supportsAnimation
   * --------------------------------------------------------
  */
  function supportsAnimation() {
    var animation = false,
        elm = document.createElement('div'),
        domPrefixes = 'Webkit Moz O ms Khtml'.split(' ');

    if (elm.style.animationName) {
      animation = true;
    }

    if(!animation) {
      $.each(domPrefixes, function(i, pfx) {
        if(elm.style[pfx + 'AnimationName'] !== undefined) {
          animation = true;
          return false;
        }
      });
    }

    return animation;
  }

  function setCaretAtEnd($elem) {
    var elemLen = $elem.val().length,
        elem = $elem.get(0),
        range;
    if (document.selection) {
      // For IE Only
      // Use IE Ranges
      range = document.selection.createRange();
      // Reset position to 0 & then set at end
      range.moveStart('character', -elemLen);
      range.moveStart('character', elemLen);
      range.moveEnd('character', 0);
      range.select();
    } else if (elem.selectionStart || elem.selectionStart == '0') {
      // Firefox/Chrome
      elem.selectionStart = elemLen;
      elem.selectionEnd = elemLen;
    }
  }

  /* --------------------------------------------------------
   * findMe
   * --------------------------------------------------------
   * reverse of closest(). will match itself or child with
   * selector
  */
  $.fn.findMe = function(selector) {
    var els;

    this.each(function() {
      var el = $(this);
      els = (el.is(selector) ? el : el.find(selector));
    });

    return els ? els : $();
  };

  /* --------------------------------------------------------
   * serializeObject
   * --------------------------------------------------------
  */
  $.fn.serializeObject = function() {
    var els = this.serializeArray(),
        obj = {};

    $.each(els, function(i, el) {
      obj[el.name] = el.value;
    });

    return obj;
  };

  /* --------------------------------------------------------
   * trimString
   * --------------------------------------------------------
   */

  function trimString(str, trim) {
    return ~str.indexOf(trim) ? str.replace(trim, '') : str;
  }

  /**
   * Change the case at x index of the string
   * @param {String} str - the string to modify
   * @param {Number=0} idx - index of the char to change case, Default: 0
   * @param {Number=0} kase - A number representing the case 0 for lowercase and 1 for uppercase, Default: lowerCase
   * @returns {String}
   */
  function changeCaseAt(str, idx, kase) {
    kase = kase || 0;
    idx = idx || 0;

    if (kase === 0) {
      return str.substr(0, idx) + str.charAt(idx).toLowerCase() + str.substr(1, str.length);
    }
    else if (kase === 1) {
      return str.substr(0, idx) + str.charAt(idx).toUpperCase() + str.substr(1, str.length);
    }

    return str;
  }

  // Validation for product and adverse event popup
  function validateForm(form){
    // Do nothing if passed object is null or empty jQuery object
    // If we proceed without this check it may lead to false negative validation fails
    if (!form || !form.length) {
      return;
    }
    var textarea = form.find('textarea'),
        autosuggest = form.find('.m-autosuggest'),
        autosuggest_ready_only = form.find('.m-autosuggest-readonly'),
        countryDropdown = form.find('.product_country_dropdown select'),
        saveProductCountryButton = form.find('.save_product_country'),
        saveButton = form.find('.m-button-next');

    var inlineVerbatim = !!((autosuggest.length) ? autosuggest.data('inline-verbatim') : autosuggest_ready_only.data('inline-verbatim'));

    function hasTextareaValue(){
      return textarea.length && textarea.val().trim().length
    }

    function hasAutosuggestValue(){
      return autosuggest.length && autosuggest.val().trim().length || autosuggest_ready_only.length
    }

    function selectingProductCountry() {
      return !countryDropdown.prop('disabled') && autosuggest.prop('disabled') && saveProductCountryButton.is(":visible");
    }

    removeError(I18n.t('javascript.need_at_least_one_error'), null);
    removeError(I18n.t('javascript.not_allow_both_error'), null);
    if (!hasAutosuggestValue() && !hasTextareaValue() && !selectingProductCountry()) {
      saveButton.prop('disabled', true);
      addError(I18n.t('javascript.need_at_least_one_error'), null, form);
    } else if (!inlineVerbatim && hasAutosuggestValue() && hasTextareaValue() && !selectingProductCountry()) {
      saveButton.prop('disabled', true);
      addError(I18n.t('javascript.not_allow_both_error'), null, form);
    } else if (autosuggest.val() && !MMM.autosuggest.validate(autosuggest, false)) {
      saveButton.prop('disabled', !MMM.autosuggest.validate(autosuggest));
    } else {
      saveButton.prop('disabled', false);
    }
    MMM.modal.resize();
  }

  function updateJumpToLink(el){
    if (el.length && $("a.m-jump-to[data-id=" + el.attr('id') + "]").length) {
      $("a.m-jump-to[data-id=" + el.attr('id') + "]").text($.trim(el.data('name')));
    }
  }

  function listenToChangeProductCountry(){
    $(document).on('click', '.change_product_country', function(e){
      var selector = $(e.target).closest('.product_country');
      disableProductField(selector, true);
      $(selector).find('.terms_country').hide();
      $(selector).find('.product_country_dropdown').removeClass('hide');
      $(selector).find('select').prop('disabled', false);
      $(selector).find('.change_product_country').hide();
      $(selector).find('.save_product_country').removeClass('hide');
      $(selector).find('button').prop('disabled', false);
      disableModalSaveButton($(selector).closest('.edit_form'), true);
      disableAutosuggestEditButton($(selector).closest(MMM.updater.selector), true);
      $.publish('/updater/error/remove', [I18n.t('javascript.need_at_least_one_error'), null]);
      $.publish('/updater/error/remove', [I18n.t('javascript.not_allow_both_error'), null]);
      refreshCountryDropdown($(selector).find('select'));
      MMM.modal.resize();
      return false;
    })
  }

  function closeChangeProductCountry(){
    $(document).find('.edit_form').each(function(){
      $(this).find('.terms_country').show();
      $(this).find('.product_country_dropdown').addClass('hide');
      $(this).find('select').prop('disabled', true);
      $(this).find('.change_product_country').show();
      $(this).find('.save_product_country').addClass('hide');
      disableModalSaveButton($(this), false);
      disableAutosuggestEditButton($(this), false);
    });
  }

  function listenToSaveProductCountry(){
    $(document).on('click', '.save_product_country', function(e){
      var selector = $(e.target).closest('.m-updater'),
          selected_country = $(selector).find('.product_country_dropdown select').val();
      MMM.updater.submit(e, {});
      disableModalSaveButton($(document).find('.edit_form'), false);
      disableAutosuggestEditButton($(selector), false);
    })
  }

  function disableProductField(selector, isDisabled){
    var input = $(selector).closest('.m-updater').find('.m-autosuggest');
    input.val('');
    input.prop('disabled', isDisabled);
    removeError(I18n.t('javascript.autosuggest_error'), null);
  }

  function disableModalSaveButton(edit_form_selector, isDisabled) {
    if (edit_form_selector.length && isDisabled) {
      edit_form_selector.find('.m-button-next').addClass('disabled');
      edit_form_selector.find('.m-button-next').prop('disabled', true);
    } else {
      edit_form_selector.find('.m-button-next').removeClass('disabled');
      edit_form_selector.find('.m-button-next').prop('disabled', false);
    }
  }

  function disableAutosuggestEditButton(selector, isDisabled) {
    var editButtonWrapper = selector.find('.m-button-edit'),
        editButton = editButtonWrapper.find('button');
    if (editButtonWrapper.length && isDisabled) {
      editButtonWrapper.addClass('disabled');
      editButton.prop('disabled', true);
    } else {
      editButtonWrapper.removeClass('disabled');
      editButton.prop('disabled', false);
    }
  }

  function initElmHelpers(){
    $(document).on('click', '.study-link-picker__actions__action', function () {
      // Defer execution to make sure Elm did its job
      setTimeout(function(){
        $('textarea.auto-expand').each(function (i, textArea) {
          expandTextArea(textArea);
        });
      }, 50);
    });
  }

  return {
    refreshCountryDropdown: refreshCountryDropdown,
    init: init
  };
}(jQuery, window.I18n));
