'use strict';
/* global JS_AIRBRAKE_KEY, JS_AIRBRAKE_ID */

require('logo.png');
require('logo2.png');
require('faq.jpg');
require('android-icon-192x192.png');
require('apple-icon-114x114.png');
require('apple-icon-120x120.png');
require('apple-icon-144x144.png');
require('apple-icon-152x152.png');
require('apple-icon-180x180.png');
require('apple-icon-57x57.png');
require('apple-icon-60x60.png');
require('apple-icon-72x72.png');
require('apple-icon-76x76.png');
require('favicon-16x16.png');
require('favicon-32x32.png');
require('favicon-96x96.png');
require('favicon.ico');
require('ms-icon-144x144.png');

const AirbrakeClient = require('airbrake-js');
require('jquery');
require('jquery-ujs');
require('jquery.ui.autocomplete');
require('jquery.ui.widget');
require('jquery.ui.mouse');
require('jquery-ui-touch-punch');
require('i18n-js');
require('jquery-autosize');
require('jquery-colorbox');
require('jquery.mousewheel');
require('jquery.nearest');
require('jquery.pubsub');
require('jquery.scrollintoview');
require('jquery.ui.selectmenu');
require('jquery.viewport');
require('bootstrap-sass');

$(document).
  ready(function () {
    const airbrake = new AirbrakeClient({
      projectId: JS_AIRBRAKE_ID,
      projectKey: JS_AIRBRAKE_KEY
    });
    airbrake.call(boot);
  });

function boot () {
  require('./../application/timezone');
  require('./../application/header-sticky');
  require('./../application/additional-context-sticky');
  require('./../application/faq');
  require('./../application/footer-links');

  // Core
  const journey = require('./../application/journey');

  journey.init();
}
